<template>
  <div @click="handleFocusOut($event)" class="admin-app-container">
    <Loading v-if="loading" />
    <Header />
    <div class="pt-1 pa-sm-6 app-container">
      <h2 class="pl-2 pr-2 pa-sm-2 text-center">Cadastro de Serviços</h2>
      <Grid
        class="grid-component"
        ref="gridComponent"
        :fields="fields"
        :list="list"
        :filters="filters"
        :defaultInsertObject="defaultInsertObject"
        gridType="responsive"
        filterType="search"
        gridOverflow="vertical"
        :gridResizable="false"
        @listarItens="listarItens($event)"
        @selecionarItem="selecionarItem($event)"
        @exportarExcel="exportarExcel($event)"
        @salvarItem="salvarItem()"
        :minimizedItemList="[]"
        gridTableId="grid-admin"
        :hideInsert="false"
      />
      <Paginacao
        id="paginacao"
        :totalPaginas="totalPages"
        :paginaAtual="filters.pagina"
        :totalItens="totalItems"
        @alterarItensPorPagina="alterarItensPorPagina($event)"
        @alterarPagina="alterarPagina($event)"
      />
      <ModalSucesso
        :mensagem="mensagemAlerta"
        :alertaSucesso="alertaSucesso"
        :titulo="mensagemTitulo"
        @ocultarSucesso="ocultarSucesso"
      />
      <MensagemErro
        :mensagemAlerta="mensagemAlerta"
        :alertaErro="alertaErro"
        @ocultarErro="alertaErro = false"
        :login="false"
        :timeout="3000"
      />
      <v-row justify="center" v-if="item">
        <v-dialog
          v-model="modalAdmin"
          max-width="600px"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Cadastro de Serviço</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closeModalAdmin">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-form ref="formAdmin" class="pt-4">
                <v-row class="pt-2 pb-2">
                  
                  <v-col cols="12" xs="12" class="pa-0 pa-sm-1">
                    <v-text-field
                      ref="refDescricao"
                      label="Serviço"
                      dense
                      v-model="item.descricao"
                      clearable
                      outlined
                      :rules="[(v) => !!v || 'O campo serviço é obrigatório']"
                      :error-messages="item.descricaoDuplicada"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" class="pa-0 pa-sm-1">
                    <v-text-field
                      ref="refPontos"
                      label="Pontos"
                      dense
                      v-model="item.ponto"
                      clearable
                      outlined
                      v-mask="['####']"
                      :rules="[(v) => !!v || 'O campo pontos é obrigatório']"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" class="pa-0 pa-sm-1">
                    <v-select
                      v-model="item.ativo"
                      item-text="text"
                      item-value="value"
                      :items="[
                        {value: true, text: 'Ativo'},
                        {value: false, text: 'Inativo'}
                      ]"
                      attach
                      label="Status"
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                  
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                right
                class="text-caption"
                @click="closeModalAdmin"
              >
                <v-icon class="text-caption">mdi-close</v-icon>Cancelar
              </v-btn>
              <v-btn color="blue" text class="text-caption" @click="salvarItem">
                <v-icon class="text-caption">mdi-content-save-outline</v-icon>
                {{ item.id ? "Salvar" : "Inserir" }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loading.vue";
import Header from "../../components/header.vue";
import Paginacao from "../../components/paginacao.vue";
import Grid from "../../components/grid.vue";
import ServicoService from "../../service/servico-service.js";
import ExcelService from "../../service/excel-service.js";
import ModalSucesso from "../../components/modal-sucesso.vue";
import MensagemErro from "../../components/mensagem-erro.vue";
import { mask } from "vue-the-mask";

const servicoService = new ServicoService();
const excelService = new ExcelService();

export default {
  directives: {
    mask,
  },
  components: {
    Loading,
    Header,
    Paginacao,
    Grid,
    ModalSucesso,
    MensagemErro,
  },
  data() {
    return {
      mensagemTitulo: "",
      mensagemAlerta: "",
      alertaSucesso: false,
      alertaErro: false,
      loading: false,
      filters: servicoService.listarFiltros(),
      modalAdmin: false,
      item: null,
      totalPages: 1,
      totalItems: 1,
      fields: [],
      list: [],
      defaultInsertObject: {
        id: null,
        descricao: null,
        ativo: true,
        ponto: null
      },
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    handleFocusOut(event) {
      this.$refs.gridComponent?.handleFocusOut(event);
    },
    async exportarExcel(filtros) {
      this.loading = true;

      let result = await servicoService.exportarServicosExcel(filtros);
      if (result?.statusCode === 200) {
        excelService.downloadFile(result?.data, "Servicos.xlsx");
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
    selecionarItem(item) {
      this.item = JSON.parse(JSON.stringify(item));
      this.modalAdmin = true;
    },
    async salvarItem() {
      this.item.descricaoDuplicada = null;

      if (this.$refs.formAdmin.validate()) {
        this.loading = true;
        this.item.ponto = parseInt(this.item.ponto);
        let result = this.item.id
          ? await servicoService.atualizar(this.item)
          : await servicoService.cadastrar(this.item);
        if (result?.statusCode === 200) {
          if (this.item.id) {
            this.mensagemAlerta =
              "O serviço '" + this.item.descricao + "' foi editado com sucesso";
            this.mensagemTitulo = "Edição de serviço";
          } else {
            this.mensagemAlerta =
              "O serviço '" + this.item.descricao + "' foi inserido com sucesso";
            this.mensagemTitulo = "Inserção de serviço";
          }
          this.alertaSucesso = true;
          this.closeModalAdmin();
          this.$refs.formAdmin.resetValidation();
        } else if (result?.statusCode === 409) {
          this.item.descricaoDuplicada = result?.data.mensagem;
          this.$refs.refDescricao.focus();
          this.loading = false;
        } else {
          this.mensagemAlerta = result?.data.mensagem;
          this.alertaErro = true;
          this.loading = false;
        }
      }
    },
    alterarPagina(pagina) {
      this.filters.pagina = pagina;
      this.listarItens();
    },
    alterarItensPorPagina(itens) {
      this.filters.itensPagina = itens;
      this.filters.pagina = 1;
      this.listarItens();
    },
    async listarItens() {
      this.loading = true;
      let result = await servicoService.listarServicos(this.filters);
      if (result?.statusCode === 200) {
        this.list = result?.data.lista;
        this.totalPages = result?.data.paginas;
        this.totalItems = result?.data.totalItens;
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
    closeModalAdmin() {
      this.loading = false;
      this.modalAdmin = false;
      this.item = null;
      const appContainer = document.querySelectorAll(".app-container")[0];
      appContainer.click();
    },
    ocultarSucesso() {
      this.alertaSucesso = false;
      this.loading = false;
      this.listarItens();
    },
  },
  created: async function () {
    this.fields = await servicoService.listarCampos();
    this.listarItens();
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
};
</script>

<style scoped>
.grid-component {
  max-height: calc(100vh - 284px) !important;
}
.admin-app-container {
  height: 100vh;
}
@media (max-width: 600px) {
  .grid-component {
    max-height: calc(100vh - 244px) !important;
  }
}
</style>